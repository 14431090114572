import React from 'react';
import './Contact.scss';

const Contact = () => {
  const gridItems = [
    {
      title: 'HORIJON SPACE',
      blocks: 'Check out platform, HORIJON SPACE (Prototype)',
      date: 'horijon.io',
      link: 'http://horijon.io/'
    },
    {
      title: 'HORIJON SPACE',
      blocks: 'Check out our page on HORIJON SPACE (TBD)',
      date: 'horijon.io/horijon',
      link: 'http://horijon.io/horijon'
    },
    {
      title: 'INSTAGRAM',
      blocks: 'Check out our Instagram @horijon.io',
      date: 'instagram.com/horijon',
      link: 'https://www.instagram.com/horijon.io'
    },
    {
      title: 'X (TWITER)',
      blocks: 'Check out our X account (TBD)',
      date: 'instagram.com/horijon',
      link: 'https://www.instagram.com/horijon.io'
    }
  ];

  return (
    <section id="contact" className="contact">
      <div className="contact__detail">
        <h3>
          [CONTACT]
        </h3>
        <h3>
          WILL@HORIJON.ORG
        </h3>
      </div>
      
      <div className="contact__grid">
        {gridItems.map((item, index) => (
          <a 
            key={index} 
            href={item.link}
            className="contact__grid-item"
            target="_blank"
          >
            <div className="content">
              <h3>{item.title}</h3>
              <h3>{item.blocks}</h3> 
              {/* <h3>{item.date}</h3> */}
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default Contact;